import { useEffect, useRef } from "react";
import styles from "./index.module.less";
const Index = () => {
  return (
    <div className={styles.container}>
      <h2>
        <span>The</span>
        <span>AI-powered</span>
        <div>Leak Detection & Removal</div>
      </h2>

      <div className={styles.main}>
        <video autoPlay loop muted playsInline>
          <source
            src="https://res-front.pumpsoul.com/prod-erasa/videos/aiPower.mp4"
            type="video/mp4"
          ></source>
        </video>
      </div>
    </div>
  );
};

export default Index;
