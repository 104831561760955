import React from "react";
import style from "./index.module.less";
import { Button } from "antd";
const Index: React.FC<any> = ({ onOpenModal }) => {
  return (
    <div className={style.container}>
      <div className={style.main}>
        <div className={style.contentMain}>
          <div>
            Start Removing Leaks with <span>Erasa</span>
          </div>
          <div>
            leak will never stop ！Don't let leaks damage your hard work. With
            Erasa, you can effortlessly remove leaked content and regain
            control.
          </div>
          <Button className={style.btn} onClick={onOpenModal}>
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
