import styles from "./index.module.less";
import { Collapse, CollapseProps, theme } from "antd";
import { CSSProperties } from "react";

const Index = () => {
  const dataList = [
    {
      label: (
        <h3 style={{ fontSize: "16px", lineHeight: "1.5rem" }}>
          Why would I use a takedown service rather than just do it myself?
        </h3>
      ),
      children: (
        <p>
          {`Use our automated takedown service for prompt, expert resolution of copyright infringements. Our deep expertise and cutting-edge automation tools minimize the chances of notice rejections, saving you time and allowing you to focus on creating content.`}
        </p>
      ),
    },
    {
      label: (
        <h3 style={{ fontSize: "16px", lineHeight: "1.5rem" }}>
          Can you guarantee removals?
        </h3>
      ),
      children: (
        <p>
          {`While we make every effort to achieve the best possible outcomes, no DMCA takedown service, including ours, can guarantee 100% success. The result of each case depends on factors such as the platform’s response, the accuracy of the claim, and the actions of the alleged infringer.`}
        </p>
      ),
    },
    {
      label: (
        <h3 style={{ fontSize: "16px", lineHeight: "1.5rem" }}>
          Is this anonymous?
        </h3>
      ),
      children: (
        <p>
          {`Yes, one of our top priorities is protecting your anonymity. When you submit a takedown notice on your own, you typically need to provide personal details like your full name, address, and more. By using our service, we submit the DMCA complaint on your behalf using our company’s information. The only non-anonymous details that will be used are your username and the official link to the content, which are essential for proving ownership.`}
        </p>
      ),
    },
  ];
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    //   marginBottom: 24,
    //   background: "#0B0B0B",
    //   border: "1px solid rgba(68,68,68,0.87);",
    //     color: "rgba(255,255,255,0.6)",

    marginBottom: 24,
    background: `#ffffff`,
    borderRadius: token.borderRadiusLG,
    border: "none",
    color: "red",
    boxShadow: `0 0 2px #D9D9D9`,
  };

  const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (
    panelStyle
  ) => {
    return dataList.map((item, index) => {
      return {
        ...item,
        key: `${index}`,
        style: panelStyle,
      };
    });
  };
  return (
    <div className={styles.container}>
      <h2>Frequently asked questions</h2>
      <div className={styles.main}>
        <Collapse
          className={styles.collapseContent}
          bordered={false}
          expandIconPosition="end"
          items={getItems(panelStyle)}
          defaultActiveKey={undefined}
        />
      </div>
    </div>
  );
};
export default Index;
