import style from "./index.module.less";
import Image from "next/image";
import classNames from "classnames";
const Index = () => {
  const dataList = [
    {
      title: "Faster Detection",
      desc: "<h3>AI Detect past and current leaks in <span style='color:#F847AA'>1 minute</span></h3>",
      p1: "<span style='color:#428DFF'>◆</span> 24/7 continuous monitoring for content.",
      p2: "<span style='color:#428DFF'>◆</span>  Catch <span style='font-weight:bold'>leaks</span> and <span style='font-weight:bold'>catfishes</span> in real time",
      wImg: "/images/home/AIGrid/g1.webp",
      itext: "250 Countries Serviced",
      iDivText1: "361 leak found in last scan",
      iDivText2: "<span style='font-weight: bolder'>Next scan in 42 min</span>",
      iDivImg: "/images/home/AIGrid/g11.webp",
      c: " <span style='font-weight:bold'>250</span> Countries Serviced",
    },
    {
      title: "Instant Takedowns",
      desc: "<h3><span style='color:#F847AA'>Unlimited removals</span> from Google and source sites</h3>",
      pt: "Automated takedown notices for swift content removal, making your leaks:",
      p1: "<span style='color:#428DFF'>◆</span>  Unfindable on search engines",
      p2: "<span style='color:#428DFF'>◆</span>  Removed from the original source",
      wImg: "/images/home/AIGrid/g2.gif",
      // itext: "***** send notices",
      iDivText1: "<span style='font-weight: bolder'>251</span> Removed",
      iDivText2: "<span style='font-weight: bolder'>300</span> Delisted",
      iDivImg: "/images/home/AIGrid/g22.webp",
      // c: "<span style='font-weight:bold'>*****</span>  send notices",
    },
    {
      title: "Immediate Feedback",
      desc: "<h3><span style='color:#F847AA'>Instant reports</span> on takedown actions</h3>",
      pt: "Daily detail reports on the handling process ensure:",
      p1: "<span style='color:#428DFF'>◆</span>  Daily Search Result Removal",
      p2: "<span style='color:#428DFF'>◆</span>  Daily Monitoring Data",
      wImg: "/images/home/AIGrid/g3.webp",
      itext: "",
      iDivText1:
        "<span style='font-weight: bolder;font-size:1rem;margin-bottom: -2px;'>95%</span>",
      iDivText2: "Success Rate",
      iDivImg: "/images/home/AIGrid/g33.webp",
    },
  ];
  return (
    <div className={style.container}>
      {dataList.map((item, index) => (
        <div
          className={classNames(style.item, { [style.wrap]: index == 1 })}
          key={index}
        >
          <div className={style.left}>
            <div>{item.title}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: item?.desc,
              }}
            />
            {item.pt && <div className={style.pt}>{item.pt}</div>}
            <div
              className={style.p1}
              dangerouslySetInnerHTML={{
                __html: item?.p1,
              }}
            />
            <div
              className={style.p2}
              dangerouslySetInnerHTML={{
                __html: item?.p2,
              }}
            />
          </div>
          <div className={style.right}>
            <Image
              src={item.wImg}
              className={style.wImg}
              width={520}
              height={360}
              layout="responsive"
              alt={item.desc}
            />
            <div
              className={classNames({
                [style.rightAbs1]: index == 0 || index == 2,
                [style.rightAbs2]: index == 1,
              })}
            >
              <Image
                src={item.iDivImg}
                className={style.iDivImg}
                width={48}
                height={48}
                alt={item.desc}
              />
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.iDivText1,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.iDivText2,
                  }}
                />
              </div>
            </div>

            {item.c && (
              <div
                className={classNames({
                  [style.cc1]: index == 0 || index == 2,
                  [style.cc2]: index == 1,
                })}
                dangerouslySetInnerHTML={{
                  __html: item?.c,
                }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Index;
